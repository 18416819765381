<template>
  <div>
    <!-- STEP 4 -->
    <div id="step4" class="wizard-step">
      <div class="row card-wrapper">
        <div class="col-md-6 col-lg-4" v-for="(package1, index) in getOrderPackages" :key="package1.selectedPackage.package_code + '-' + index">
          <div class="package-card-wrapper">
            <div>
              <div class="group">
                <label>Package:</label>
                <div class="data-display">{{ package1.name }}</div>
              </div>
              <div class="group">
                <label>Sampling Location:</label>
                <div class="data-display">
                  {{ package1.differentAddressSampling == 'yes' ? userData.company_address : package1.serviceAddress }}
                </div>
              </div>
              <div class="group">
                <label>Sample Point:</label>
                <div class="data-display">{{ package1.samplePoint }}</div>
              </div>
              <div class="group">
                <label>Price:</label>
                <div class="data-display">{{ package1.packageItemTotals[2] }}</div>
              </div>
            </div>

            <div>
              <div class="" v-if="package1.duplicateOf" style="font-size: 14px">Duplicate of {{ package1.duplicateOf }}</div>
              <div class="btn-group-custom pull-right">
                <button class="btn btn-info btn-sm" style="margin-right: 4px" @click="editOrder(index)">Edit</button>
                <button v-if="!package1.duplicateOf" class="btn btn-info btn-sm" style="margin-right: 4px" @click="duplicateOrderModal(index)">
                  Duplicate
                </button>
                <button
                  class="btn btn-danger btn-sm mr-1"
                  @click="removePackageFromOrderLocal(index)"
                  :disabled="disableRemoveDuplicate(index)"
                  v-tooltip.top="{ content: 'Delete the last duplicate first', visible: disableRemoveDuplicate(index) }"
                >
                  Remove
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <button class="btn btn-default ripple waves-effect pull-right margin-top-20" v-on:click="addAnotherPackageLocal()">
        <i class="glyphicon glyphicon-plus"></i>
        Add another Package
      </button>
      <div class="clearfix"></div>
    </div>

    <!-- DUPLICATE MODAL -->
    <div id="duplicatePackageModal" class="modal fade" role="dialog">
      <div class="modal-dialog">
        <!-- Modal content-->
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal">&times;</button>
            <h4 class="modal-title">Duplicate Package</h4>
          </div>
          <div class="modal-body">
            <div class="price-warning mb-4 pt-3 pb-3">
              Create a duplicate of the selected package with the same standard and microbiological tests.

              <div class="mt-2">
                If you would like to customize the standard and microbiological tests, close this dialouge and use the "Add another Package" instead.
              </div>
            </div>
            <div class="weblab-form step4-duplicate-form">
              <PackageDetailsForm ref="packageDetailsFormRef" />
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
            <button type="button" class="btn btn-info" v-on:click="duplicateOrder()">Duplicate</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import MaterialCheckbox from '../../../../generic/MaterialCheckbox.vue';
import PackageDetailsForm from '../PackageDetailsForm.vue';
import * as helpers from '../../../../../helpers/functions.js';

export default {
  name: 'Step4',
  components: { MaterialCheckbox, PackageDetailsForm },
  computed: {
    ...mapGetters({
      getOrderPackages: 'getOrderPackages',
      userData: 'userData'
    })
  },
  data() {
    return {
      selectedIndexForDuplication: null
    };
  },
  methods: {
    ...mapActions({
      removePackageFromOrder: 'removePackageFromOrder'
    }),
    ...mapMutations({
      editOrder: 'editOrder',
      updateCurrentStep: 'updateCurrentStep',
      clearWizard: 'clearWizard',
      calculateTotalOrdersPrice: 'calculateTotalOrdersPrice'
    }),
    addAnotherPackageLocal() {
      this.updateCurrentStep(1);
      this.clearWizard();
    },
    removePackageFromOrderLocal(index) {
      this.$snotify.info(`${this.getOrderPackages[index].name} removed from order`, '', {
        timeout: 3000,
        showProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        position: 'rightTop'
      });

      this.removePackageFromOrder(index);
      this.updatePrices();
    },
    updatePrices() {
      this.calculateTotalOrdersPrice();
    },
    duplicateOrderModal(index) {
      this.selectedIndexForDuplication = index;
      jQuery('#duplicatePackageModal')
        .appendTo('body')
        .modal('show');
    },
    duplicateOrder() {
      if (!this.$refs.packageDetailsFormRef.validate()) return;
      this.$store.commit('duplicatePackage', this.selectedIndexForDuplication);
      jQuery('#duplicatePackageModal').modal('hide');
    },
    disableRemoveDuplicate(index) {
      if (this.getOrderPackages[index]?.duplicateOf == null) return false;
      if (index > 0 && this.getOrderPackages[index + 1]?.duplicateOf != null) return true;
      else return false;
    }
  },
  created: function() {
    this.updatePrices();
  },
  mounted() {
    //reset values modal
    jQuery('#duplicatePackageModal').on('hidden.bs.modal', () => {
      this.$refs.packageDetailsFormRef.resetValues();
    });
  },
  destroyed: function() {
    helpers.removeModalFromBody('duplicatePackageModal');
  }
};
</script>

<style lang="scss"></style>
