<template>
  <div id="create-order-wizard">
    <transition :name="getwizardAnimationClass">
      <div v-if="currentStep === 1" :key="currentStep">
        <Step1></Step1>
      </div>
      <div v-if="currentStep === 2" :key="currentStep">
        <Step2></Step2>
      </div>
      <div v-if="currentStep === 3" :key="currentStep">
        <Step3 ref="step3Ref"></Step3>
      </div>
      <div v-if="currentStep === 4" :key="currentStep">
        <Step4></Step4>
      </div>
    </transition>
    <hr />

    <!-- back -->
    <button
      class="ripple btn-lg waves-effect btn-primary btn"
      v-on:click="wizardNavigationHandles(currentStep - 1, 'back')"
      :disabled="!getNavButtons.backButtonEnabled"
      v-html="getNavButtons.backButtonText"
      v-if="currentStep < 4"
    ></button>
    <!-- save -->
    <button
      class="ripple btn-lg waves-effect pull-right btn-success btn"
      v-on:click="savePackgeEditLocal({ currentOrder: getCurrentOrder, index: getEditIndex })"
      :disabled="!getNavButtons.nextButtonEnabled"
      v-if="getEditMode && currentStep === 3"
    >
      Save Changes
    </button>
    <!-- next -->
    <button
      class="ripple btn-lg waves-effect pull-right btn-primary btn"
      v-on:click="wizardNavigationHandles(currentStep + 1, 'next')"
      :disabled="!getNavButtons.nextButtonEnabled"
      v-html="getNavButtons.nextButtonText"
      v-else-if="currentStep < 4"
    ></button>
    <!-- Cancel -->
    <button
      class="ripple btn-lg waves-effect pull-right btn-default btn"
      v-on:click="cancelPackageEditLocal()"
      :disabled="!getNavButtons.nextButtonEnabled"
      v-if="getEditMode && currentStep <= 3"
    >
      Cancel Edit
    </button>
    <!-- add package -->
    <!-- <button
      class="btn btn-default ripple btn-lg waves-effect pull-right add-other-btn"
      v-on:click="addAnotherPackageLocal()"
      v-if="currentStep === 3 && !getEditMode"
    >
      <i class="glyphicon glyphicon-plus"></i>
      Add another Package
    </button> -->

    <transition name="scale">
      <div v-if="currentStep === 4">
        <div class="prices-wrapper">
          <div class="price-line">
            <span class="desc">Price Package(s): </span>
            <span class="pull-right">{{ currency }}{{ getOrderTotalPrice }}</span>
          </div>
          <div class="price-line taxes">
            <span class="desc">Admin Costs: </span>
            <span class="pull-right">{{ currency }}{{ adminCosts }} </span>
          </div>
          <!-- <div class="price-line taxes">
            <span class="desc">BBO ({{ taxes.bbo }}%): </span>
            <span class="pull-right">{{ currency }}{{ getOrderData.orderTotalPriceBBO }} </span>
          </div>
          <div class="price-line taxes">
            <span class="desc">BAZV ({{ taxes.bazv }}%): </span>
            <span class="pull-right">{{ currency }}{{ getOrderData.orderTotalPriceBAZV }} </span>
          </div>
          <div class="price-line taxes">
            <span class="desc">BAVP ({{ taxes.bavp }}%): </span>
            <span class="pull-right">{{ currency }}{{ getOrderData.orderTotalPriceBAVP }} </span>
          </div> -->

          <hr />
          <div class="price-line">
            <span class="desc">Price Total: </span>
            <span class="pull-right">{{ currency }}{{ getOrderData.orderTotalPriceWithTaxes }}</span>
          </div>
        </div>

        <div class="well price-warning col-sm-6 col-sm-offset-6">
          <i class="glyphicon glyphicon-info-sign"></i>
          <div class="title">Final price may vary.</div>
          <div class="text">Extra fees might be included depending on addiotional requests.</div>
        </div>
      </div>
    </transition>

    <transition name="scale">
      <button class="btn btn-info ripple waves-effect pull-right" v-on:click="createOrderPrompt()" v-if="currentStep === 4">
        <i class="glyphicon glyphicon-ok"></i>
        Place Order
      </button>
    </transition>

    <div class="clearfix"></div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import Step1 from './Step1/Step1.vue';
import Step2 from './Step2/Step2.vue';
import Step3 from './Step3/Step3.vue';
import Step4 from './Step4/Step4.vue';

export default {
  name: 'CreateOrderWizard',
  data() {
    return {};
  },
  components: { Step1, Step2, Step3, Step4 },
  computed: {
    ...mapGetters({
      currentStep: 'currentStep',
      getNavButtons: 'getNavButtons',
      backButtonEnabled: 'backButtonEnabled',
      nextButtonEnabled: 'nextButtonEnabled',
      getOrderPackages: 'getOrderPackages',
      getCurrentOrder: 'getCurrentOrder',
      getEditMode: 'getEditMode',
      getEditIndex: 'getEditIndex',
      getwizardAnimationClass: 'getwizardAnimationClass',
      getOrderTotalPrice: 'getOrderTotalPrice',
      currency: 'currency',
      adminCosts: 'adminCosts',
      getOrderData: 'getOrderData',
      taxes: 'taxes',
    }),
  },
  watch: {
    selected: function (n) {
      console.log(n.value);
    },
  },
  methods: {
    ...mapActions({
      setCurrentStep: 'setCurrentStep',
      createOrder: 'createOrder',
      clearWizard: 'clearWizard',
      setPackgeOrder: 'setPackgeOrder',
    }),
    ...mapMutations({
      setWizardAnimationClass: 'setWizardAnimationClass',
      cancelPackageEdit: 'cancelPackageEdit',
    }),

    wizardNavigationHandles(step, direction) {
      if (direction === 'next') {
        this.setWizardAnimationClass('wizard-forward');
      } else if (direction === 'back') {
        this.setWizardAnimationClass('wizard-back');
      }

      if (step === 4) {
        if (!this.$refs.step3Ref.$refs.packageDetailsFormRef.validate()) return;
        this.setPackgeOrder({ currentOrder: this.getCurrentOrder, index: this.getOrderPackages.length });
        setTimeout(
          function () {
            this.setWizardAnimationClass('wizard-back');
          }.bind(this),
          1000
        );
      }

      this.setCurrentStep(step);
    },
    savePackgeEditLocal(payload) {
      if (!this.$refs.step3Ref.$refs.packageDetailsFormRef.validate()) return;
      this.setPackgeOrder(payload);
      this.setCurrentStep(4);
      this.$snotify.success(`${payload.currentOrder.selectedPackage.package_name} saved.`, '', {
        timeout: 3000,
        showProgressBar: false,
        pauseOnHover: false,
        position: 'rightTop',
      });
    },
    cancelPackageEditLocal() {
      this.cancelPackageEdit();
      this.setCurrentStep(4);
    },
    addAnotherPackageLocal() {
      this.displayOrderToast();
      this.setPackgeOrder({ currentOrder: this.getCurrentOrder, index: this.getOrderPackages.length });
      this.setCurrentStep(1);
      this.setWizardAnimationClass('wizard-back');
      this.clearWizard();
    },
    displayOrderToast() {
      this.$snotify.info(`Package added to order`, '', {
        timeout: 3000,
        showProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        position: 'rightTop',
      });
    },
    createOrderPrompt() {
      jQuery('#createOrderModal').appendTo('body').modal('show');
    },
  },
  destroyed: function () {
    this.cancelPackageEdit();
  },
};
</script>

<style lang="scss">
</style>
