<template>
  <div id="create-order-main-page">
    <Navigation></Navigation>
    <div class="dashboard-container-right" v-if="created">
      <ProgressSteps></ProgressSteps>

      <!-- select2 admin client select -->
      <div class="select-client-wrapper filter-wrappers" v-if="isDashboardAdmin">
        <label class="label-well">Create order for client</label>
        <button v-if="selectedClient" @click="clearClientSelectLocal()" title="Clear client" class="select2-clear-link btn-sm btn btn-default">
          &#10005;
        </button>
        <div>
          <v-select
            @input="setSelectedUserIdByAdmin"
            v-model="selectedClient"
            :debounce="250"
            :on-search="getOptions"
            :options="options"
            label="label"
            placeholder="Search company name..."
          >
          </v-select>
        </div>
      </div>

      <div class="header" v-if="currentStep > 1">
        <hr />
        <h3 v-if="selectedPackage && selectedPackage.package_name && currentStep < 4" class="step-header pull-left">
          {{ selectedPackage.package_name }}
        </h3>
        <span v-if="getEditMode" class="edit-mode-indicator">Edit Mode</span>
        <h3 class="step-header pull-left" v-if="currentStep === 4">Place Order</h3>
        <button @click="resetCurrentOrder(true)" class="pull-right btn btn-deffault btn-sm" v-if="currentStep < 4 && !getEditMode">
          <i class="glyphicon glyphicon-remove"></i> Reset Order
        </button>
        <button @click="resetOrderPackages" class="pull-right btn btn-deffault btn-sm" v-if="currentStep == 4">
          <i class="glyphicon glyphicon-remove"></i> Clear Packages
        </button>
        <span class="pull-right" v-if="currentStep > 1">
          <!-- <Cart></Cart> -->
        </span>
        <div class="clearfix"></div>
        <hr />
      </div>
      <CreateOrderWizard v-if="currentStep < 5"></CreateOrderWizard>
      <CreateOrderModal></CreateOrderModal>
    </div>
  </div>
</template>

<script>
//import Modal from '../generic/Modal.vue'
import axios from 'axios';
import Navigation from '../../generic/Navigation.vue';
import ProgressSteps from './CreateOrderWidget/ProgressSteps.vue';
import CreateOrderWizard from './CreateOrderWidget/CreateOrderWizard.vue';
import CreateOrderModal from './CreateOrderWidget/CreateOrderModal.vue';
import { mapActions, mapGetters, mapMutations } from 'vuex';

import * as helpers from '../../../helpers/functions.js';

export default {
  name: 'CreateOrder',
  data() {
    return {
      created: false,
      options: [],
      selectedClient: null
    };
  },
  components: { Navigation, ProgressSteps, CreateOrderWizard, CreateOrderModal },
  computed: {
    ...mapGetters({
      currentStep: 'currentStep',
      selectedPackage: 'selectedPackage',
      userData: 'userData',
      isDashboardAdmin: 'isDashboardAdmin',
      getEditMode: 'getEditMode',
      getOrderPackages: 'getOrderPackages',
      getOrderData: 'getOrderData'
    })
  },
  methods: {
    ...mapActions({
      getInvoiceData: 'getInvoiceData'
    }),
    ...mapMutations({
      setSelectedClientIdByAdmin: 'setSelectedClientIdByAdmin',
      resetCurrentOrder: 'resetCurrentOrder',
      updateCurrentStep: 'updateCurrentStep',
      resetOrderPackages: 'resetOrderPackages',
      setServiceAddress: 'setServiceAddress'
    }),
    getOptions(search, loading) {
      //select2
      loading(true);
      axios({
        method: 'get',
        url: this.$store.state.config.baseUrlApi + '/api/clients',
        params: {
          q: search
        }
      })
        .then(response => {
          this.options = response.data.map(option => {
            let obj = {
              label: option.company_name,
              id: option.id,
              company_address: option.company_address
            };
            return obj;
          });
          loading(false);
        })
        .catch(error => {
          reject();
          console.log(error);
        });
    },
    setSelectedUserIdByAdmin(obj) {
      if (obj == null && this.isDashboardAdmin) this.setSelectedClientIdByAdmin(null);
      else {
        this.setSelectedClientIdByAdmin(obj);
        this.setServiceAddress(obj.company_address);
      }
    },
    clearClientSelectLocal() {
      this.selectedClient = null;
      this.setSelectedClientIdByAdmin(null);
      this.setServiceAddress(null);
    },
    getInvoiceDataLocal() {
      this.getInvoiceData()
        .then(data => {})
        .catch(error => {
          console.log(error);
          helpers.displayError(error + ': Invoice data', 'getInvoiceDataLocal', this);
        });
    }
  },
  created: function() {
    if (this.getOrderPackages.length > 0) this.updateCurrentStep(4);
    if (!this.getOrderData.adminCosts) this.getInvoiceDataLocal();
  },

  updated: function() {},
  mounted: function() {
    this.created = true;
  },

  destroyed: function() {
    helpers.removeModalFromBody('createOrderModal');
    this.resetCurrentOrder();
  }
};
</script>

<style lang="css"></style>
