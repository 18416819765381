<template>
  <div class="bs-wizard">
      <div class="col-xs-3 bs-wizard-step"  v-bind:class="step.class" v-for="(step, index) in stepsData" :key="step.title">
        <div class="text-center bs-wizard-stepnum">Step {{ index + 1}}</div>
        <div class="progress"><div class="progress-bar"></div></div>
        <span class="bs-wizard-dot" v-on:click="wizardNavigationHandles(index+1)"></span>
        <div class="bs-wizard-info text-center">{{step.title}}</div>
      </div>      
  </div>
</template>

<script>

import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
  name: 'ProgressSteps',
  data () {
    return {
      stepsData:[
        {
          title:'Select Package',
          class: 'disabled'
        },
        {
          title:'Package Items',
          class: 'disabled'
        },
        {
          title:'Package Details',
          class: 'disabled'
        },
        {
          title:'Place Order',
          class: 'disabled'
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      currentStep: 'currentStep',
      selectedPackage: 'selectedPackage',
      getEditMode: 'getEditMode'
    }),
  },
  watch: {
    currentStep(n,o) {
      this.setCurrentStepPos();
    }
  },
  created: function() {
    this.setCurrentStepPos();
  },
  updated: function(){

  },
  methods: {
  ...mapActions({
      setCurrentStep: 'setCurrentStep'
    }),

    ...mapMutations({
      setWizardAnimationClass: 'setWizardAnimationClass'
    }),

    setCurrentStepPos(){
      let stepsData = this.stepsData.map(function(stepData, index){
        if(this.currentStep > index + 1){
          return {
            title: stepData.title,
            class: 'complete' 
          } 
        }else if (this.currentStep === index + 1){
          return {
            title: stepData.title,
            class: 'active' 
          }
        }else{
          return {
          title: stepData.title,
          class: 'disabled' 
          }
        }
      }.bind(this));
      //console.log(stepsData);
      
      this.stepsData = stepsData;
    },

    wizardNavigationHandles(step){ 
      if(this.currentStep == '4') return;   
      if(this.currentStep < step){
        this.setWizardAnimationClass('wizard-forward');
      }else {
        this.setWizardAnimationClass('wizard-back');
      }


      this.setCurrentStep(step);

    },

  }

}
</script>

<style lang="scss">
 @import '@/assets/styles/_variables.scss';
  .bs-wizard {margin-top: 40px;}
  /*Form Wizard*/
  .bs-wizard {padding: 0 0 10px 0;border-bottom: none;overflow:hidden; margin-bottom: 20px;}
  .bs-wizard > .bs-wizard-step {padding: 0; position: relative;}
  .bs-wizard > .bs-wizard-step + .bs-wizard-step {}
  .bs-wizard > .bs-wizard-step .bs-wizard-stepnum {color: #595959; font-size: 16px; margin-bottom: 5px;}
  .bs-wizard > .bs-wizard-step .bs-wizard-info {color: #595959; font-size: 14px;}
  .bs-wizard > .bs-wizard-step > .bs-wizard-dot {pointer-events: none; position: absolute; width: 30px; height: 30px; display: block; background:darken($lightBlue,10%); top: 45px; left: 50%; margin-top: -15px; margin-left: -15px; border-radius: 50%;} 
  .bs-wizard > .bs-wizard-step > .bs-wizard-dot:after {content: ' '; width: 14px; height: 14px; background: $brightBlue; border-radius: 50px; position: absolute; top: 8px; left: 8px; } 
  .bs-wizard > .bs-wizard-step > .progress {position: relative; border-radius: 0px; height: 8px; box-shadow: none; margin: 20px 0; left: 50%}
  .bs-wizard > .bs-wizard-step > .progress > .progress-bar {width:0px; box-shadow: none; background: darken($lightBlue,10%);}
  .bs-wizard > .bs-wizard-step.complete > .progress > .progress-bar {width:100%;}
  .bs-wizard > .bs-wizard-step.active > .progress > .progress-bar {width:0%;}
  .bs-wizard > .bs-wizard-step:first-child.active > .progress > .progress-bar {width:0%;}
  .bs-wizard > .bs-wizard-step:last-child.active > .progress > .progress-bar {width: 100%;}
  .bs-wizard > .bs-wizard-step.disabled > .bs-wizard-dot {background-color: #f5f5f5;}
  .bs-wizard > .bs-wizard-step.disabled > .bs-wizard-dot:after {opacity: 0;}
  //.bs-wizard > .bs-wizard-step:first-child  > .progress {left: 50%; width: 50%;}
  .bs-wizard > .bs-wizard-step:last-child  > .progress {width: 0%;}
  .bs-wizard > .bs-wizard-step.disabled a.bs-wizard-dot{ pointer-events: none; }

  .bs-wizard{
    .complete{
      .bs-wizard-dot{
        pointer-events: all;
        &:hover{
          cursor: pointer;
        }
      }
    }
  }
</style>
