<template>
  <div id="order-modal-wrapper">
    <!-- Modal -->
    <div id="createOrderModal" class="modal fade" role="dialog">
      <div class="modal-dialog modal-lg">
        <!-- Modal content-->
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal">&times;</button>
            <h4 class="modal-title">Terms and Conditions</h4>
          </div>
          <div class="modal-body">
            <div class="terms-conditions-text" v-html="termsAndConditions"></div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
            <button type="button" class="btn btn-info" data-dismiss="modal" v-on:click="createOrderLocal()">I Agree</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import * as helpers from '../../../../helpers/functions.js';

export default {
  name: 'CreateOrderModal',
  data() {
    return {};
  },
  components: {},
  computed: {
    ...mapGetters({
      getOrderPackages: 'getOrderPackages',
      getOrderData: 'getOrderData',
      userData: 'userData',
      isDashboardAdmin: 'isDashboardAdmin',
      termsAndConditions: 'termsAndConditions',
    }),
  },
  methods: {
    ...mapActions({
      removePackageFromOrder: 'removePackageFromOrder',
      createOrder: 'createOrder',
      getOrdersStatesCount: 'getOrdersStatesCount',
      unselectOrderMade: 'unselectOrderMade',
    }),
    ...mapMutations({
      clearWizard: 'clearWizard',
      updateCurrentStep: 'updateCurrentStep',
      clearOrderPackages: 'clearOrderPackages',
      setSelectedClientIdByAdmin: 'setSelectedClientIdByAdmin',
    }),
    createOrderLocal() {
      this.$store.commit('setTransLoader', true);
      let orderDataFull = { orderData: this.getOrderData, orderPackages: this.getOrderPackages };

      this.createOrder(orderDataFull)
        .then((data) => {
          this.clearWizard();
          this.clearOrderPackages();
          this.updateCurrentStep(1);
          this.setSelectedClientIdByAdmin(null);
          this.getOrdersStatesCount();
          this.$router.push('/orders');
          this.unselectOrderMade();
          this.$snotify.success('Order ' + data.order_number + ' created', 'Success', {
            timeout: 10000,
            showProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            position: 'rightTop',
          });
          setTimeout(() => this.emailNotification(data.mail), 1000);
        })
        .catch((error) => {
          console.log(error);
          helpers.displayErrorNoRetry(error, this);
        })
        .finally(() => this.$store.commit('setTransLoader', false));
    },
    emailNotification(data) {
      if (data) {
        this.$snotify.info('Confirmation email sent to ' + data, '', {
          timeout: 10000,
          showProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          position: 'rightTop',
        });
      }
    },
  },
  created: function () {},
  updated: function () {},
  mounted: function () {},
};
</script>

<style lang="scss">
.cart-wrapper {
  &:hover {
    cursor: pointer;
  }
}
</style>
