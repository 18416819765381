<template>
  <form>
    <!-- different location -->
    <div class="form-item required">
      <label for="different-sampling-address">Sampling at same location as company address?</label>
      <select
        id="different-sampling-address"
        v-model="differentAddressSampling"
        class="form-control"
        @change="$store.commit('setDifferentAddressSampling', $event.target.value)"
        :class="{ error: $v.differentAddressSampling.$error }"
        v-model.trim="$v.differentAddressSampling.$model"
      >
        <option :value="null" disabled>Select</option>
        <option :value="'yes'">Yes</option>
        <option :value="'no'">No</option>
      </select>
      <div class="error description" v-if="!$v.differentAddressSampling.required && $v.differentAddressSampling.$dirty">Field is required</div>
      <div v-if="userData" class="description">Company address set to: {{ userData.company_address }}</div>
    </div>

    <!-- service locaion -->
    <transition name="scale">
      <div class="form-item mt-3 required" v-if="differentAddressSampling == 'no'">
        <label for="address">Sampling Location</label>
        <input
          :class="{ error: $v.serviceAddress.$error }"
          v-model.trim="$v.serviceAddress.$model"
          placeholder="eg. WEB N.V."
          class="form-control"
          type="text"
          v-on:keyup="$store.commit('setServiceAddress', $event.target.value)"
        />
        <div class="error description" v-if="!$v.serviceAddress.required && $v.serviceAddress.$dirty">Field is required</div>
        <div class="description">Fill location where sample must be taken</div>
      </div>
    </transition>

    <!-- sanple point -->
    <div class="form-item required mt-3">
      <label for="address">Sample Point</label>
      <input
        :class="{ error: $v.samplePoint.$error }"
        v-model.trim="$v.samplePoint.$model"
        class="form-control"
        placeholder="eg. Kantine"
        type="text"
        v-on:keyup="$store.commit('setSamplePoint', $event.target.value)"
      />
      <div class="error description" v-if="!$v.samplePoint.required && $v.samplePoint.$dirty">Field is required</div>
      <div class="description">Fill in the sample point, eg. 'Kitchen, Katine'</div>
    </div>

    <!-- Sample done by web lab -->
    <div class="form-item mt-3 required">
      <label for="sample-done-by-web-lab">Sampling done by WEB LAB?</label>
      <select
        id="sample-done-by-web-lab"
        v-model="sampleDoneByWebLab"
        class="form-control"
        @change="$store.commit('setSampleDoneByWebLab', $event.target.value)"
        :class="{ error: $v.sampleDoneByWebLab.$error }"
        v-model.trim="$v.sampleDoneByWebLab.$model"
      >
        <option :value="null" disabled>Select</option>
        <option :value="'yes'">Yes</option>
        <option :value="'no'">No</option>
      </select>
      <div class="error description" v-if="!$v.sampleDoneByWebLab.required && $v.sampleDoneByWebLab.$dirty">Field is required</div>
      <div v-if="userData" class="description">Additional Sampling cost will be added to the quote</div>
    </div>

    <!-- additional requests -->
    <div class="form-item mt-4">
      <label for="additional-message">Additional Requests or Comments</label>
      <textarea
        class="form-control additional-message"
        name="additional-message"
        :value="getPackageSpecialRequests"
        @keyup="$store.commit('setPackageSpecialRequests', $event.target.value)"
      >
      </textarea>
    </div>
  </form>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required, requiredIf } from 'vuelidate/lib/validators';
import { mapGetters, mapMutations } from 'vuex';

export default {
  mixins: [validationMixin],
  computed: {
    ...mapGetters({
      getDifferentAddressSampling: 'getDifferentAddressSampling',
      getSampleDoneByWebLab: 'getSampleDoneByWebLab',
      getServiceAddress: 'getServiceAddress',
      getSamplePoint: 'getSamplePoint',
      getPackageSpecialRequests: 'getPackageSpecialRequests',
      userData: 'userData'
    })
  },
  data() {
    return {
      serviceAddress: null,
      samplePoint: null,
      differentAddressSampling: null,
      sampleDoneByWebLab: null
    };
  },
  validations: {
    serviceAddress: {
      required: requiredIf(nestedModel => {
        return nestedModel.differentAddressSampling == 'no';
      })
    },
    samplePoint: { required },
    differentAddressSampling: { required },
    sampleDoneByWebLab: { required }
  },

  created() {
    this.serviceAddress = this.getServiceAddress;
    this.samplePoint = this.getSamplePoint;
    this.differentAddressSampling = this.getDifferentAddressSampling;
    this.sampleDoneByWebLab = this.getSampleDoneByWebLab;
  },
  methods: {
    validate() {
      this.$v.$touch();
      if (this.$v.$invalid) return false;
      else return true;
    },
    ...mapMutations({
      setServiceAddress: 'setServiceAddress',
      setSamplePoint: 'setSamplePoint'
    }),
    resetValues() {
      this.$v.$reset();
      this.serviceAddress = null;
      this.samplePoint = null;
      this.differentAddressSampling = null;
    }
  }
};
</script>
