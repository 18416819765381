<template>
  <div id="step2" class="wizard-step">
    <div v-if="!packageItems.length" class="loader-dashboard-page"><div></div></div>

    <div v-if="packageItems.length" class="package-items-tables">
      <div class="standard-text-wrapper" v-if="packageItems[0].length > 0">
        <div class="table-title">Standard Tests</div>
        <div class="price-warning table-slide">Slide table to right to view all columns</div>
        <div class="table-responsive">
          <table class="table table-hover table-standard-tests table-tests">
            <thead>
              <tr>
                <th>
                  <MaterialCheckbox
                    class="material-checkbox"
                    :selected="currentOrder.selectAll[0]"
                    @checkboxChanged="setPackageItemSelectedAll(0)"
                  ></MaterialCheckbox>
                </th>
                <th>Analysis</th>
                <th>Unit</th>
                <th>Price</th>
                <!-- <th>Quantity</th> -->
                <!-- <th>Total</th> -->
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in packageItems[0]" :key="item.uuid + '-' + index">
                <td class="checkbox-td">
                  <MaterialCheckbox
                    class="material-checkbox"
                    :selected="item.selected"
                    @checkboxChanged="checkBoxClicked($event, 0, index)"
                  ></MaterialCheckbox>
                </td>
                <td>{{ item.name_analysis }}</td>
                <td v-if="item.measurement_unit">{{ item.measurement_unit.measurement_unit }}</td>
                <td v-else class="lightgrey">NA</td>
                <td>{{ item.price_awg }}</td>
                <!-- <td>
                  <select v-model="item.quantity" @change="getQuantityTotal(item.quantity)">
                    <option v-for="quantity in quantitySelect" :key="quantity" :value="quantity"> {{quantity}}</option>
                  </select>
                </td> -->
                <!-- <td>{{item.total_item_price}}</td> -->
              </tr>
            </tbody>
          </table>
        </div>
        <div class="table-row-totals">Total: {{ currency }} {{ getTotalPriceItems[0] }}</div>
      </div>

      <div class="extra-text-wrapper" v-if="packageItems[1].length > 0">
        <hr />
        <div class="table-title">Microbiological Tests</div>
        <div class="price-warning table-slide">Slide table to right to view all columns</div>
        <div class="table-responsive extra-table-wrapper">
          <table class="table table-hover table-standard-tests table-tests">
            <thead>
              <tr>
                <th>
                  <MaterialCheckbox
                    class="material-checkbox"
                    :selected="currentOrder.selectAll[1]"
                    @checkboxChanged="setPackageItemSelectedAll(1)"
                  ></MaterialCheckbox>
                </th>
                <th>Analysis</th>
                <th>Unit</th>
                <th>Price</th>
                <!-- <th>Quantity</th> -->
                <!-- <th>Total</th> -->
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in packageItems[1]" :key="item.uuid + '-' + index">
                <td class="checkbox-td">
                  <MaterialCheckbox
                    class="material-checkbox"
                    :selected="item.selected"
                    @checkboxChanged="checkBoxClicked($event, 1, index)"
                  ></MaterialCheckbox>
                </td>
                <td>{{ item.name_analysis }}</td>
                <td v-if="item.measurement_unit">{{ item.measurement_unit.measurement_unit }}</td>
                <td v-else class="lightgrey">NA</td>
                <td>{{ item.price_awg }}</td>
                <!-- <td>
                      <select v-model="item.quantity" @change="getQuantityTotal(item.quantity)">
                        <option v-for="quantity in quantitySelect" :key="quantity" :value="quantity"> {{quantity}}</option>
                      </select>
                    </td> -->
                <!-- <td>{{item.total_item_price}}</td> -->
              </tr>
            </tbody>
          </table>
        </div>
        <div class="table-row-totals">Total: {{ currency }} {{ getTotalPriceItems[1] }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import MaterialCheckbox from '@/components/generic/MaterialCheckbox.vue';
import * as helpers from '@/helpers/functions.js';

export default {
  name: 'Step2',
  data() {
    return {
      //selectedPackageCode: null
      quantitySelect: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
    };
  },
  components: { MaterialCheckbox },
  computed: {
    ...mapGetters({
      //packages: 'packages',
      selectedPackage: 'selectedPackage',
      packageItems: 'packageItems',
      getTotalPriceItems: 'getTotalPriceItems',
      backButtonEnabled: 'backButtonEnabled',
      nextButtonEnabled: 'nextButtonEnabled',
      currency: 'currency',
      currentOrder: 'currentOrder'
    })
  },
  methods: {
    ...mapActions({
      getPackageItems: 'getPackageItems',
      getQuantityTotal: 'getQuantityTotal'
    }),
    ...mapMutations({
      enableBackButton: 'enableBackButton',
      enableNextButton: 'enableNextButton',
      nextButtonText: 'nextButtonText',
      setPackageItemSelected: 'setPackageItemSelected',
      setTotalPriceItems: 'setTotalPriceItems',
      setPackageItemSelectedAll: 'setPackageItemSelectedAll'
    }),
    checkBoxClicked(e, type, index) {
      this.setPackageItemSelected({ e, type, index });
      this.checkIfItemsSelected();
    },
    getPackageItemsLocal() {
      this.getPackageItems(this.selectedPackage.package_code)
        .then(() => this.enableNextButton(true))
        .catch(error => {
          console.log(error, 'local');
          helpers.displayError(error + ': package items', 'getPackageItemsLocal', this, this.selectedPackage.package_code);
        });
    },
    checkIfItemsSelected() {
      for (const item of this.packageItems[0]) {
        if (item.selected == true) {
          this.enableNextButton(true);
          return;
        }
      }
      for (const item of this.packageItems[1]) {
        if (item.selected == true) {
          this.enableNextButton(true);
          return;
        }
      }
      this.enableNextButton(false);
    }
  },
  created: function() {
    this.nextButtonText('reset');
    this.enableBackButton(true);
    this.enableNextButton(false);
    if (!this.packageItems.length) this.getPackageItemsLocal(this.selectedPackage.package_code);
    else this.enableNextButton(true);
  },
  updated: function() {},
  mounted: function() {}
};
</script>

<style lang="scss"></style>
