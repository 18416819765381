<template>
  <div id="step3" class="wizard-step">
    <div class="weblab-form">
      <div class="row">
        <div class="col-sm-6">
          <PackageDetailsForm ref="packageDetailsFormRef" />
        </div>

        <div class="totals-wrapper col-sm-6">
          <h3>{{ selectedPackage.package_name }}</h3>
          <table class="table">
            <tr>
              <td>Standard tests:</td>
              <td>{{ getTotalPriceItems[0] }}</td>
            </tr>
            <tr class="border-bottom">
              <td>Microbiological tests:</td>
              <td>{{ getTotalPriceItems[1] }}</td>
            </tr>
            <tr style="font-size: 18px;">
              <td>Total:</td>
              <td>{{ getTotalPriceItems[2] }}</td>
            </tr>
          </table>

          <!-- <div class="well price-warning">
            <i class="glyphicon glyphicon-info-sign"></i>
            This cost belongs to the current selected package.
          </div> -->
        </div>
      </div>
      <!-- row -->
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import MaterialCheckbox from '../../../../generic/MaterialCheckbox.vue';
import PackageDetailsForm from '../PackageDetailsForm.vue';

export default {
  name: 'Step3',
  components: { MaterialCheckbox, PackageDetailsForm },
  computed: {
    ...mapGetters({
      getTotalPriceItems: 'getTotalPriceItems',
      selectedPackage: 'selectedPackage'
    })
  },
  methods: {}
};
</script>

<style lang="scss"></style>
