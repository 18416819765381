<template>
  <div id="step1" class="wizard-step">
    <div v-if="!getEditMode" class="step-1-header">
      <h3>Please select a package and click on next to continue to step 2</h3>
      <div class="clearfix"></div>
    </div>

    <div v-if="getEditMode" class="clearfix">
      <div class="edit-mode-indicator" style="margin-top: 8px">Edit Mode</div>
    </div>
    <hr />
    <div class="row">
      <template v-if="packages.length">
        <div
          v-for="packageItem in packages"
          class="col-lg-12"
          :key="packageItem.id"
          :class="[
            selectedPackage.package_code == packageItem.package_code ? 'card-parent-active' : '',
            selectedPackage.package_code != packageItem.package_code && selectionInitialized ? 'card-parent-not-active' : '',
          ]"
        >
          <div
            class="card-wrapper"
            :class="[
              selectedPackage.package_code == packageItem.package_code ? 'active' : '',
              selectedPackage.package_code != packageItem.package_code && selectionInitialized ? 'not-active' : '',
            ]"
          >
            <!-- image -->
            <div
              :style="`background-image: url(${$store.state.config.baseUrlApi}/api/private-files/package-images/${packageItem.package_image_path})`"
              style="width: 200px; height: 200px; background-size: cover; background-position: center"
              class="img"
              v-if="packageItem.package_image_path"
            ></div>

            <div class="text-wrapper">
              <!-- text -->
              <div>
                <div class="title">
                  <span>
                    {{ packageItem.package_name }}
                  </span>
                </div>
                <div class="code">Package code: {{ packageItem.package_code }}</div>
                <truncate
                  action-class="read-more"
                  class="description"
                  clamp="Read More"
                  :length="120"
                  less="Show Less"
                  type="html"
                  :text="packageItem.package_description"
                ></truncate>
              </div>
              <!-- button -->
              <div class="btn btn-primary btn-select" @click="selectPackageLocal(packageItem.package_code)">
                <MaterialCheckbox class="material-checkbox" :selected="selectedPackage.package_code == packageItem.package_code"></MaterialCheckbox>
                <span class="text">
                  {{ selectedPackage.package_code == packageItem.package_code ? 'Selected' : 'Select' }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
    <div class="clearfix"></div>
    <div v-if="!packages.length" class="loader-dashboard-page"><div></div></div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import * as helpers from '@/helpers/functions.js';
import MaterialCheckbox from '../../../../generic/MaterialCheckbox.vue';
import truncate from 'vue-truncate-collapsed';

export default {
  name: 'Step1',
  data() {
    return {
      selectedPackageLocal: '',
      descriptionRawHtml: null,
      selectPackageTooltip: false,
      selectionInitialized: false,
    };
  },
  components: { MaterialCheckbox, truncate },
  computed: {
    ...mapGetters({
      packages: 'packages',
      selectedPackage: 'selectedPackage',
      backButtonEnabled: 'backButtonEnabled',
      nextButtonEnabled: 'nextButtonEnabled',
      currentStep: 'currentStep',
      getEditMode: 'getEditMode',
    }),
    selectedPackageName: {
      set: function () {
        this.selectedPackage.package_name;
      },
      get: function () {
        if (this.selectedPackage.package_name) {
          return this.selectedPackage.package_name;
        } else return '';
      },
    },
  },
  methods: {
    ...mapActions({
      getPackages: 'getPackages',
    }),
    ...mapMutations({
      selectPackage: 'selectPackage',
      unselectPackage: 'unselectPackage',
      enableBackButton: 'enableBackButton',
      enableNextButton: 'enableNextButton',
      nextButtonText: 'nextButtonText',
    }),
    selectPackageLocal(selected) {
      this.selectPackageTooltip = false;
      //if already selected
      if (selected === this.selectedPackage.package_code) {
        this.selectPackageLocal();
        this.enableNextButton(false);
        this.selectionInitialized = false;
        return;
      }
      this.selectionInitialized = true;
      this.enableNextButton(true);
      this.selectPackage(selected);
    },
    getPackagesLocal() {
      if (this.packages.length === 0) {
        this.getPackages()
          .then((data) => {
            this.selectedPackageLocal = this.selectedPackage;
          })
          .catch((error) => {
            helpers.displayError(error + ': packages', 'getPackagesLocal', this);
          });
        //tooltip
        setTimeout(
          function () {
            this.selectPackageTooltip = true;
          }.bind(this),
          2000
        );
      } else {
        this.descriptionRawHtml = this.selectedPackage.description;
        this.enableNextButton(true);
      }
    },
    unescapeLocal(url) {
      return url.replace(/&amp;/g, '&');
    },
    setSelectionInitialized() {
      if (this.selectedPackage.package_code) this.selectionInitialized = true;
      else this.selectionInitialized = false;
    },
  },
  created: function () {
    this.enableBackButton(false);
    this.getPackagesLocal();
    this.nextButtonText('Next <i class="glyphicon glyphicon-chevron-right"></i>');
    this.setSelectionInitialized();

    //if no package selected disable next
    if (Object.keys(this.selectedPackage).length === 0 && this.selectedPackage.constructor === Object) {
      this.enableNextButton(false);
    }
  },
  updated: function () {},
  mounted: function () {},
};
</script>
